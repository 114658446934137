


















































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldPhoto from '@/components/FieldPhoto.vue';
import FieldRepeater from '@/components/FieldRepeater.vue';
import ROUTE from '@/graphql/queries/ScheduledRoute.graphql';
import ROUTE_COMPLETE from '@/graphql/mutations/RouteComplete.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import formatDate from '@/utils/formatDate';
import { ROUTE_STATUSES } from '@/utils/constants';
import {
  ScheduledRoute,
  RouteCompleteMutationVariables,
  ScheduledRouteQueryVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'RouteComplete',
  components: {
    FieldRepeater,
    AppMain,
    AppForm,
    FieldPhoto,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        totalWeight: '' as string | number,
        farmTicketPhotos: [] as { file: string }[],
      },
      route: {} as ScheduledRoute,
    };
  },
  computed: {
    hasRoute(): boolean {
      return this.route && !!this.route.id;
    },
    currentDate(): string {
      const today = new Date();
      return formatDate(today);
    },
  },
  validations() {
    return {
      form: {
        totalWeight: { required, decimal },
        farmTicketPhotos: {
          required,
          $each: {
            file: { required },
          },
        },
      },
    };
  },
  methods: {
    validationMessages,
    async completeRoute() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: ROUTE_COMPLETE,
          variables: {
            input: {
              routeId: this.route.id,
              totalWeight: this.form.totalWeight,
              farmTickets: this.form.farmTicketPhotos,
            },
          } as RouteCompleteMutationVariables,
        });
        this.$notify({
          text: 'Route complete!',
          type: 'success',
          duration: 6000,
        });

        const isAdminRoute = this.$route.name?.startsWith('admin');
        const name = isAdminRoute ? `admin-schedules` : 'driver-route-start';
        this.$router.push({ name, params: { date: this.route.date } });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
  apollo: {
    route: {
      query: ROUTE,
      variables(): ScheduledRouteQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ route: ScheduledRoute }>) {
        if (!data) return;
        if (!data.route || data.route.status === ROUTE_STATUSES.COMPLETED) {
          this.$router.replace(this.$route.meta.backTo);
        } else if (data.route.status === ROUTE_STATUSES.UNSTARTED) {
          this.$notify({
            text: 'Route must be started first!',
            type: 'error',
            duration: 6000,
          });
          this.$router.replace({
            name: this.$route.matched[0].name + '-route-start',
          });
        }
      },
    },
  },
});
